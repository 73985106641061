import { createSlice } from "@reduxjs/toolkit";
import { ICardMini } from "@/shared/types/types";

interface IInitialState {
  cartDisable: boolean;
  priceBlockDisable: boolean;
  activeProducts: ICardMini[];
}

const initialState: IInitialState = {
  cartDisable: false,
  priceBlockDisable: false,
  activeProducts: [],
};

export const areaDisable = createSlice({
  name: "areaDisable",
  initialState,
  reducers: {
    setCartDisable: (
      state: any,
      action: {
        payload: boolean;
      }
    ) => {
      state.cartDisable = action.payload;
    },
    setPriceBlockDisable: (
      state: any,
      action: {
        payload: boolean;
      }
    ) => {
      state.priceBlockDisable = action.payload;
    },
    setCartActiveProducts: (
      state: any,
      action: {
        payload: ICardMini[];
      }
    ) => {
      state.activeProducts = action.payload;
    },
  },
});

export const { setCartDisable, setPriceBlockDisable, setCartActiveProducts } = areaDisable.actions;

export const isCartDisable = (state: { areaDisable: IInitialState }) => state.areaDisable.cartDisable;
export const priceBlockDisable = (state: { areaDisable: IInitialState }) => state.areaDisable.priceBlockDisable;
export const cartActiveProducts = (state: { areaDisable: IInitialState }) => state.areaDisable.activeProducts;

export default areaDisable.reducer;
