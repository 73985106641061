import { request } from "../functions/request";
export const postReviewRequest = async (data: any) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  data.files.forEach((file: any, i: any) => {
    formData.append(`files[${i}]`, file);
  });
  return request("/account/reviews/index.php", {
    method: "POST",
    body: formData
  });
};