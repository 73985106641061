import { callMessage } from "../functions/callMessage";
import { request } from "../functions/request";

export const authAdmin = (login: string, pass: string) => {
  if (!login || !pass) {
    callMessage("Ошибка!", "Заполните все поля!", false);
    return;
  }
  return request(`/auth/login.php?login=${login}&password=${pass}`);
};
