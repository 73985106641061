// Уведомления {
// metods: ['GET', 'PATCH']
// GET: /account/notifications/index.php?< all=true | > - если не передавать get параметр получишь только  не почитанные если передать то все
// PATCH:  /account/notifications/index.php?id=${notifications_id} - это прочиать
// METOD: patch
// ?study=all - прочитать всё
// ?id=<id уведомления> - прочитать конкретное уведомления
// ?study=page&page=<page int (опционально)> - почитать не прочитанные по странично
// ?page&page=<page int (опционально)> - прочить по стронично но из всего пула (почесть если будет уже прочитанное то с ним ничего не произойдёт если оно было не прочитано прочитается )
import { request } from "../functions/request";

export const getUserNotifications = async (page?: number, type?: string) => {
  return request(
    `/account/notifications/index.php?all=true&page=${page ?? 1}${type && type !== "all" ? `&type=${type}` : ""}`
  );
};
export const getUserNotificationsNotCheck = async () => {
  return request("/account/notifications/index.php");
};

export const checkAllNotifications = async () => {
  return request("/account/notifications/index.php?study=all", {
    method: "PATCH",
  });
};

export const checkNotificationsOnPage = async (page: number, type?: string) => {
  return request(`/account/notifications/index.php?page=${page ?? 1}${type && type !== "all" ? `&type=${type}` : ""}`, {
    method: "PATCH",
  });
};

export const checkNotification = async (id: number) => {
  return request(`/account/notifications/index.php?id=${id}`, {
    method: "PATCH",
  });
};

export const getNotificationsCounts = async () => {
  return request("/account/notifications/count.php");
};
