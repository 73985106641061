"use client";

import { clearUserInfo } from "@/shared/redux/features/userSlice";
import { getCookie } from "./cookie";
import { refreshToken } from "./refresh-token";
export const checkToken = async (dispatch, getState, res, func) => {
  const isAuth = getState().user.user.auth.isAuth;
  if (isAuth && !getCookie("refreshToken")) {
    dispatch(clearUserInfo());
  }
  if (typeof window !== "undefined" && getCookie("refreshToken") && res.auth === false) {
    await dispatch(refreshToken(func()));
    return false;
  }
  if (res.success === false && res.error_msg === "Вы не авторизованы") {
    dispatch(clearUserInfo());
  }
  return true;
};