import { removeCookie, setCookie } from "./cookie";
type TTokens = (accessToken: string, refreshToken: string) => void;
export const saveTokens: TTokens = (accessToken, refreshToken) => {
  const dayExpires = 60 * 60 * 24;
  setCookie("accessToken", accessToken, {
    expires: dayExpires
  });
  setCookie("refreshToken", refreshToken, {
    expires: dayExpires * 30
  });
};
export const clearTokens: any = () => {
  removeCookie("accessToken");
  removeCookie("refreshToken");
};